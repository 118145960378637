<template>
  <div>
    <div class="home-bg1">
      <div class="content-a wr">
        <div class="w1520 f-cb">
          <div class="left">
            <div class="tit hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">
              GIS与数字孪生 (GIS & Digital Twin)
              <div class="title_divider" style="background: rgb(0, 59, 179);"></div>
            </div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;font-size: 18px;color:#fff;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  地理信息系统 (GIS)：GIS是用于采集、存储、分析和展示地理空间数据的系统，它能够将现实世界中的地理位置、属性和空间信息进行整合分析。通过GIS技术，可以在地图上直观展示数据，
                  从而帮助用户进行空间分析、资源规划、城市管理、环境监控等。它为决策者提供了精准的地理信息支持，优化了资源配置和管理效率。
                  <br>
                  数字孪生 (Digital Twin)：数字孪生技术通过在虚拟空间中创建物理实体或系统的数字副本，提供了对实体的实时监控和行为预测。它利用传感器、数据采集和分析工具，将物理世界的变化
                  映射到虚拟模型中，从而模拟和预测实际系统的运行状态。数字孪生技术被广泛应用于工业生产、建筑设计、智能城市等领域，通过虚拟仿真和实时监控，提供精确的数据支持和智能决策能力，
                  帮助优化运营效率、减少风险并提升系统性能。
                </span>
              </p><p><br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-b wr">
        <div class="w1520">
          <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">主要功能</div>
          <div class="list">
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;" v-for="(item,index) in functions" :key="index">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">{{item.subheading}}</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;" v-for="(it,i) in item.content" :key="i"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">{{it}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content-b wr">
        <div class="w1520">
          <div class="left wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">
            <img src="../../img/fuwuBg.jpg" alt="总体概况">
          </div>
          <div class="right">
            <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">总体概况</div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">迪倍特设备运维管理云平台（迪倍特巡检云）是集设备运维管理、数据储存、数据处理为一体的云计算平台，该平台通过对设备的巡检、点检、维修以及运行状态等数据信息进行收集、传输、储存、统计、分析、预测和维护，来提高设备工作效率和维护维修效率，系统自动生成相关任务、报表及设备运行报告，迪倍特以建设信息化设施设备运维系统，保障高效化安全生产体系目标，融合标准化、流程化、协作化、数字化、精细化管理理念，为企业打造全员参与的高校协同的设备运维管理SAAS平台。</span></p><p><br></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
          functions: [
            {
              subheading: 'GIS（地理信息系统）',
              content: [
                '空间数据管理：存储、查询和分析地理信息（如地图、地形、高度、气象等）',
                '缓冲区分析（Buffer Analysis）：计算某个点、线、面的影响范围',
                '叠加分析（Overlay Analysis）：分析两个不同的地理数据层之间的关系',
                '可视域分析（Visibility Analysis）：计算观察点可见的区域，如监控摄像头视角分析',
                '地图可视化：支持2D/3D 地图渲染，结合遥感影像、点云数据、矢量地图等多种数据格式',
              ]
            },
            {
              subheading: '数字孪生（Digital Twin）',
              content: [
                '物理-虚拟映射：通过传感器、IoT 设备、遥感数据，构建与真实世界同步的虚拟模型',
                '数据驱动决策：实时分析设备运行状态、环境变化，并预测未来趋势',
                '三维可视化：结合 GIS、BIM（建筑信息建模），构建高精度 3D 场景',
                '智能仿真：通过 AI 和大数据，优化系统运行，提高效率和安全性',
              ]
            },
          ],
        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
  user-select: none;
  width: 100%;
  position: relative;
  overflow: auto;
  .content-a {
    background-image: url('../../img/coreTechnology4.jpg');
    background-size: cover;
    .w1520 {
      padding: 120px 0 16px;
      border-bottom: 1px solid #d5d6d6;
    }
    .left {
      width: 73%;
      float: left;
      .tit {
        font-weight: 500;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 34px;
       .title_divider {
          width: 40px;
          height: 6px;
          margin-top: 14px;
        } 
      }
      .describe {
        color: #fff;
        font-size: 20px;
        width: 80%;
        overflow: hidden;
        .txt { 
          margin-bottom: 10px;
          text-align: justify;
        }
      }
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
    .right {
      width: 50%;
      padding-left: 126px;
      float: left;
    }
  }
  .content-b {
    padding: 60px 0;
    .list {
      margin-top: 49px;
      .li {
        width: calc(47%);
        height: 320px;
        border-radius: 5px;
        box-shadow: -1px 0px 12px rgba(0, 0, 0, 0.1);
        float: left;
        margin-right: 40px;
        display: flex;
        padding: 40px 20px;
        margin-bottom: 50px;
        transition: all 0.5s;
        padding-right: 40px;
        .txt {
          // width: calc(40%);
          padding-left: 12px;
          .tit-18 {
            font-size: 0;
            text-align: left;
            margin-top: 20px;
            margin-left: 20px;
            p {
              // display: inline-block;
              // width: 50%;
              font-size: 16px;
              line-height: 2;
              color: #666666;
              padding-left: 18px;
              transition: all 0.5s;
              position: relative;
            }
            p::after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              background-color: #666;
              opacity: 0.5;
              border-radius: 50%;
              top: 10px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .content-c {
    padding: 60px 0;
    .left {
      width: 50%;
      padding-right: 126px;
      float: left;
    }
    .right {
      width: 50%;
      float: left;
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
  }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        // margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>